import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Hero } from '../components/InnerPages/Hero';
import { GatsbyLink } from '../components/Links/GatsbyLink';

import style from '../styles/pages/write-partner.scss';

interface IWritePartnerProps {
  location: any;
}

const WritePartner = ({ location }: IWritePartnerProps) => (
  <>
    <MetaData data={{}} location={location} title="Write / Partner" />
    <Layout headerClassName={style.header} mainClass={style.main}>
      <Hero
        title="Guest posting opportunities"
        description="ThinkSaveRetire.com welcomes guest posts that provide unique insights into financial independence and early retirement. Everything shared on this blog is designed to be truly valuable, unique, and interesting."
      />

      <div className={style.content}>
        <div className="container">
          <div className="row">
            <article className="col-12 col-lg-8 m-auto">
              <p>
                Due to the number of guest post requests, the standards for guest post submissions
                are intentionally high (described below).
              </p>
              <p>
                Quality is the #1 determining factor for all posts that are published on
                ThinkSaveRetire.com.
              </p>
              <p>
                This blog gets between <b>80,000 and 100,000 pageviews a month</b>. The newsletter
                contains over <b>5,500 email subscribers.</b>
              </p>
              <h2>Submit a guest post request</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    '<div class="typeform-widget" data-url="https://form.typeform.com/to/Q2X6eb" style="width: 100%; height: 500px;"></div> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script> <div style="font-family: Sans-Serif;font-size: 12px;color: #999;opacity: 0.5; padding-top: 5px;"> powered by <a href="https://admin.typeform.com/signup?utm_campaign=Q2X6eb&utm_source=typeform.com-01E5ZD44624ATET3ASF3VPZDJ7-free&utm_medium=typeform&utm_content=typeform-embedded-poweredbytypeform&utm_term=EN" style="color: #999" target="_blank">Typeform</a> </div>',
                }}
              ></div>
              <h2>Guest Post Popular topics</h2>
              <p>
                Guest posts should be 100% relevant to this blog and the reader base. Popular topics
                include:
              </p>
              <ul>
                <li>Financial independence</li>
                <li>Early retirement</li>
                <li>Side-hustles</li>
                <li>Digital nomad life</li>
                <li>Wealth building</li>
                <li>Family finances</li>
              </ul>
              <p>
                Also, <b>we love personal stories.</b> If you have a story that's super unique,
                personal, exciting or just downright interesting, throw us the details below. The
                top priority with the topic choice is uniqueness. If we could find the topic on any
                other money or personal finance blog out there, it's probably not right for this
                blog. Your topic must be clear and specific, unique and interesting.
              </p>
              <h2>Important information about guest posts</h2>
              <p>
                <b>All guest authors need to come prepared with at least a few topics in mind.</b>{' '}
                Guest post requests that do not include clear ideas for posting topics will not be
                accepted - and due to the volume of requests received, we may not be able to respond
                to these submissions.
              </p>
              <p>
                We are looking for posts that are <b>at least 1,500 words.</b> Longer pieces are
                okay provided the content is substantive, helpful and well-written. Long posts offer
                more "food for thought" and better overall value to the reader and is becoming
                increasingly important in the blogosphere.
              </p>
              <p>
                The very first link in your guest post should <b>link internally</b> to another post
                on this blog that compliments your piece. Thereafter, you are permitted to include a
                couple of additional links back to your site - within reason. Generally, two or
                three, max. These links should be included naturally within the writing and not come
                across as salesy. We reserve the right to include <b>rel=”nofollow”</b> in any links
                included within the guest post. "No follow" links discourage search engines from
                "following" and counting the backlink.
              </p>
              <p>
                Source all statistics to <b>credible outlets</b> and{' '}
                <b>always include citations for images</b> that are not in the public domain (you
                must also have legal permission to use all images in your post). Pixabay.com and
                Unsplash.com are two popular websites that offer images that can be used for any
                purpose.{' '}
              </p>
              <p>
                Use <b>proper headings</b> (h2, h3, etc) within the content. A guest post without
                any headings will be disregarded outright. Additionally, write using smaller
                paragraphs whenever possible.
              </p>
              <p>
                Lastly, while we will edit your post for general fitness on our blog,{' '}
                <b>we are not your primary editors.</b> Guest posts should be edited for spelling
                and grammar before they are submitted. Posts that contain numerous errors will be
                disregarded.
              </p>
              <h2>Publishing Process</h2>
              <p>
                Be sure to <b>get your topic idea approved before starting to write the piece.</b>{' '}
                The first step in this process is completing the form above. Once your idea is
                approved, you are free to begin writing the article.
              </p>
              <p>
                We prefer a shared Google Doc, but your piece can also be submitted as an attachment
                to an email or straight in the body of an email.
              </p>
              <p>
                Once your guest post is received, we will review it and inform you of any changes.
                We make an effort not to change the meaning of the piece but reserve the right to
                alter word use or perform other changes to correct for spelling/grammar mistakes or
                make the post fit better on the blog.
              </p>
              <p>
                Once the piece is officially accepted, we will turn it into an official post and
                will schedule it for publishing.
              </p>
              <p>
                Please note that guest posts can be rejected or deleted for <i>any</i> reason.
              </p>
              <h2>After Publishing</h2>
              <p>
                Once your post is published, we encourage you to share it with your social media
                following as you like. There is no minimum number of shares or mentions that you
                must make of the post, but the more popular the guest post is, the more referral
                traffic your website will receive.
              </p>
              <p>
                You are more than welcome (and encouraged) to respond to any comments that the post
                receives, especially those that pertain to you. You may NOT republish the guest post
                on any other site, including yours. If we find that it has been republished, it may
                be removed from this blog, and your chances of publishing another guest post in the
                future may also be removed.
              </p>
              <p>
                <b>
                  Guest posts are written <i>ONLY</i> for this blog.
                </b>
              </p>
              <h2>Contacting methods</h2>
              <p>
                <ul>
                  <li>
                    If you are interested in writing a <b>sponsored post,</b>{' '}
                    <GatsbyLink to="/sponsored-posts/">see this page.</GatsbyLink>
                  </li>
                  <li>
                    General requests or questions,{' '}
                    <GatsbyLink to="/contact/">see our contact page.</GatsbyLink>
                  </li>
                </ul>
              </p>
            </article>
          </div>
        </div>
      </div>
      <SignUpBanner />
    </Layout>
  </>
);

export default WritePartner;
