import React from 'react';

import style from './GatsbyLink.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IGatsbyLinkProps {
  to: string;
  className?: string | string[];
  children?: React.ReactNode;
}

export const GatsbyLink = (props: IGatsbyLinkProps) => {
  const { to, className, children, ...restProps } = props;

  return (
    <Link {...restProps} to={to} className={cn(className, style.link)}>
      {children}
    </Link>
  );
};
